import React, { useState, useEffect } from 'react';
import { ArrowLeft, Plus, Trash2 } from 'lucide-react';

const API_URL = 'https://api.uf-am-boda.ch:5000/api';

const SettingsPage = ({ onBack }) => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/categories`);
      if (!response.ok) {
        throw new Error('Kategorien konnten nicht abgerufen werden');
      }
      const data = await response.json();
      setCategories(data);
      setError('');
    } catch (error) {
      console.error('Fehler beim Abrufen von Kategorien:', error);
      setError('Die Kategorien konnten nicht geladen werden. Bitte versuche es erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  const addCategory = async () => {
    if (newCategory.trim() === '') {
      setError('Kategoriename darf nicht leer sein');
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newCategory.trim() }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Kategorie kann nicht hinzugefügt werden: ${response.status} ${response.statusText}`);
      }

      const addedCategory = await response.json();
      setCategories(prevCategories => [...prevCategories, addedCategory]);
      setNewCategory('');
      setSuccess('Kategorie erfolgreich hinzugefügt');
    } catch (error) {
      console.error('Fehler beim Hinzufügen der Kategorie:', error);
      setError(`Kategorie kann nicht hinzugefügt werden: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCategory = async (categoryId) => {
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/categories/${categoryId}`, {
        method: 'DELETE',
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Die Kategorie konnte nicht gelöscht werden');
      }

      setCategories(prevCategories => prevCategories.filter(cat => cat._id !== categoryId));
      setSuccess(data.message || 'Kategorie erfolgreich gelöscht');
    } catch (error) {
      console.error('Fehler beim Löschen der Kategorie:', error);
      setError(`Die Kategorie konnte nicht gelöscht werden: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gray-800 min-h-screen text-white">
      {/* Header with title and back button */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Einstellungen</h1>
        <button 
          onClick={onBack}
          className="text-white hover:text-gray-300 transition-colors"
        >
          <ArrowLeft size={24} />
        </button>
      </div>
      
      {/* Category Management Section */}
      <div className="bg-gray-700 p-4 rounded-lg mb-4">
        <h2 className="text-xl font-semibold mb-4">Kategorie-Verwaltung</h2>
        
        {/* Add new category */}
        <div className="flex mb-4">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Neue Kategorie"
            className="flex-grow p-2 rounded-l bg-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={addCategory}
            disabled={isLoading}
            className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? 'Adding...' : <Plus size={20} />}
          </button>
        </div>

        {/* Error and success messages */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        {/* List of categories */}
        {isLoading && categories.length === 0 ? (
          <p>Loading categories...</p>
        ) : categories.length > 0 ? (
          <ul className="space-y-2">
            {categories.map((category) => (
              <li key={category._id} className="flex justify-between items-center bg-gray-600 p-2 rounded">
                <span>{category.name}</span>
                <button
                  onClick={() => deleteCategory(category._id)}
                  className="text-red-500 hover:text-red-600 focus:outline-none"
                  disabled={isLoading}
                  title="Delete category"
                >
                  <Trash2 size={20} />
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>Keine Kategorien gefunden. Fügen Sie eine neue Kategorie hinzu, um zu beginnen.</p>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;