import React, { useState, useEffect, useCallback } from 'react';
import { Settings, ChevronDown, ChevronRight, Plus } from 'lucide-react';
import ItemTile from './ItemTile';
import ItemSettingsModal from './ItemSettingsModal';
import SettingsPage from './SettingsPage';

// Base URL for the API
const API_URL = 'https://api.uf-am-boda.ch:5000/api';

// Colors used in the application
const SHOPPING_LIST_COLOR = 'bg-[#ee524f]';
const DEFAULT_ITEM_COLOR = 'bg-[#4faba2]';

const ShoppingList = () => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  // Function to check if an item already exists (case-insensitive)
  const itemExists = (name) => {
    return items.some(item => item.name.toLowerCase() === name.toLowerCase());
  };

  // Fetch categories from the API
  const fetchCategories = useCallback(async () => {
    try {
      console.log('Fetching categories...');
      const response = await fetch(`${API_URL}/categories`);
      console.log('Categories response:', response);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch categories');
      }
      const fetchedCategories = await response.json();
      console.log('Fetched categories:', fetchedCategories);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(`Failed to load categories. ${error.message}`);
    }
  }, []);

  // Fetch items from the API
  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/items`);
      if (!response.ok) {
        throw new Error('Failed to fetch items');
      }
      const fetchedItems = await response.json();
      setItems(fetchedItems.map(item => ({
        ...item,
        category: categories.find(cat => cat.id === item.category_id) || { id: 1, name: 'Sonstiges' }
      })));
    } catch (error) {
      console.error('Error fetching items:', error);
      setError('Failed to load items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [categories]);

  // Fetch items and categories on component mount
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (categories.length > 0) {
      fetchItems();
    }
  }, [categories, fetchItems]);

  // Log items state updates
  useEffect(() => {
    console.log('Items state updated:', items);
  }, [items, forceUpdate]);

  // Add a new item to the shopping list
  const addItem = async (name) => {
    setError(null);
    try {
      console.log('Adding item:', name);

      // Check if the item already exists (case-insensitive)
      if (itemExists(name)) {
        throw new Error('Dieser Artikel existiert bereits.');
      }
      
      let categoryId;
      const sonstigesCategory = categories.find(cat => cat.name === 'Sonstiges');
      
      if (sonstigesCategory) {
        categoryId = sonstigesCategory.id;
      } else {
        console.warn('Kategorie "Sonstiges" nicht gefunden, erste verfügbare Kategorie verwenden');
        categoryId = categories[0]?.id;
      }

      if (!categoryId) {
        throw new Error('Keine gültige Kategorie gefunden');
      }

      const response = await fetch(`${API_URL}/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          icon: '/icons/default.png',
          category: categoryId,
          isShoppingListItem: true
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Artikel kann nicht hinzugefügt werden');
      }

      const newItem = await response.json();
      console.log('New item added:', newItem);
      setItems(prev => [...prev, newItem]);
      setNewItemName('');
    } catch (error) {
      console.error('Fehler beim Hinzufügen eines Artikels:', error);
      setError(`Artikel kann nicht hinzugefügt werden: ${error.message}`);
    } finally {
      fetchItems();
    }
  };

  // Handle form submission for adding new items
  const handleAddItem = (e) => {
    e.preventDefault();
    if (newItemName.trim() !== '') {
      addItem(newItemName.trim());
    }
  };

  // Toggle the shopping list status of an item
  const toggleShoppingListStatus = async (itemId) => {
    const itemToUpdate = items.find(item => item.id === itemId);
    if (!itemToUpdate) {
      console.error('Artikel nicht gefunden:', itemId);
      setError('Artikel kann nicht aktualisiert werden: Artikel nicht gefunden');
      return;
    }

    const category = categories.find(cat => cat.id === itemToUpdate.category_id) || { id: 1, name: 'Sonstiges' };
    
    if (!category) {
      console.error('Kategorie nicht gefunden für Artikel:', itemToUpdate);
      setError('Artikel kann nicht aktualisiert werden: Kategorie nicht gefunden');
      return;
    }

    try {
      console.log('Artikel aktualisieren:', itemId);
      const response = await fetch(`${API_URL}/items/${itemId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: itemToUpdate.name,
          icon: itemToUpdate.icon,
          category: category.id,
          isShoppingListItem: !itemToUpdate.isShoppingListItem,
          useCount: (itemToUpdate.useCount || 0) + 1,
          detail: itemToUpdate.detail
        }),
      });

      console.log('Antwort aktualisieren:', response);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Artikel konnte nicht aktualisiert werden');
      }

      const updatedItem = await response.json();
      console.log('Aktualisierter Artikel:', updatedItem);
      setItems(prev => prev.map(item => 
        item.id === itemId ? updatedItem : item
      ));
      setError(null);
    } catch (error) {
      console.error('Error updating item:', error);
      setError(`Failed to update item: ${error.message}`);
    }
  };

  // Add details to an item
  const addDetailToItem = async (itemId, detail) => {
    const itemToUpdate = items.find(item => item.id === itemId);
    if (!itemToUpdate) {
      console.error('Artikel nicht gefunden:', itemId);
      setError('Artikel kann nicht aktualisiert werden: Artikel nicht gefunden');
      return;
    }

    const updatedItem = {
      ...itemToUpdate,
      detail: detail
    };

    try {
      console.log('Updating item:', itemId, 'with data:', updatedItem);
      const response = await fetch(`${API_URL}/items/${itemId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update item detail');
      }

      const updatedItemResponse = await response.json();
      console.log('Item updated with new detail:', updatedItemResponse);

      setItems(prevItems => {
        const newItems = prevItems.map(item => 
          item.id === itemId ? updatedItemResponse : item
        );
        console.log('Updated items state:', newItems);
        return newItems;
      });

      setForceUpdate(prev => !prev);
      setError(null);
    } catch (error) {
      console.error('Error updating item detail:', error);
      setError(`Failed to update item detail: ${error.message}`);
    }
  };

  // Open item settings modal
  const openItemSettings = (item) => {
    setSelectedItem(item);
  };

  // Close item settings modal
  const closeItemSettings = () => {
    setSelectedItem(null);
  };

  // Update item details
  const updateItemDetails = async (itemId, updates) => {
    try {
      console.log('Sending update for item:', itemId, 'with data:', updates);
      
      const response = await fetch(`${API_URL}/items/${itemId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });

      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to update item: ${response.status} ${response.statusText}`);
      }

      const updatedItem = await response.json();
      console.log('Updated item:', updatedItem);
      
      setItems(prev => prev.map(item => 
        item.id === itemId ? updatedItem : item
      ));
    } catch (error) {
      console.error('Error updating item:', error);
      setError(`Failed to update item: ${error.message}`);
    }
  };

  // Toggle settings page
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  // Toggle category expansion
  const toggleCategory = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  // Render settings page if showSettings is true
  if (showSettings) {
    return <SettingsPage onBack={toggleSettings} />;
  }

  // Filter items for the shopping list and most used items
  const shoppingListItems = items.filter(item => item.isShoppingListItem);
  const mostUsedItems = [...items]
    .sort((a, b) => (b.useCount || 0) - (a.useCount || 0))
    .slice(0, 10);

  return (
    <div className="container mx-auto p-4 bg-gray-800 min-h-screen text-white">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Einkaufsliste "uf am Boda, Saas"</h1>
        <button 
          onClick={toggleSettings}
          className="text-white hover:text-gray-300 transition-colors p-2"
        >
          <Settings size={24} />
        </button>
      </div>

      {/* Input for adding new items */}
      <form onSubmit={handleAddItem} className="mb-6 flex flex-col sm:flex-row gap-2">
        <input 
          type="text" 
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          placeholder="Was wird uf am Boda benötigt?"
          className="flex-grow p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
        />
        <button 
          type="submit" 
          className="p-3 bg-blue-500 text-white rounded-lg flex items-center justify-center"
        >
          <Plus size={24} className="mr-2" />
          <span className="hidden sm:inline">Hinzufügen</span>
        </button>
      </form>

      {error && (
        <div className="bg-red-500 text-white p-4 rounded mb-4">
          <p>{error}</p>
          <button 
            onClick={() => {
              setError(null);
              fetchCategories();
              fetchItems();
            }}
            className="mt-2 bg-white text-red-500 px-4 py-2 rounded"
          >
            Retry Loading Data
          </button>
        </div>
      )}

      {isLoading ? (
        <p>Lade Artikel...</p>
      ) : (
        <>
          {/* Current shopping list */}
          <div className="mb-8 bg-gray-700 p-4 rounded-lg">
            <h2 className="text-xl md:text-2xl font-bold mb-4">Aktueller Einkaufszettel</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
              {shoppingListItems.map((item) => (
                <ItemTile 
                  key={item.id} 
                  icon={item.icon} 
                  name={item.name} 
                  color={SHOPPING_LIST_COLOR}
                  onToggleShoppingList={() => toggleShoppingListStatus(item.id)} 
                  onAddDetail={(detail) => addDetailToItem(item.id, detail)} 
                  detail={item.detail}
                  onOpenSettings={() => openItemSettings(item)}
                />
              ))}
            </div>
            {shoppingListItems.length === 0 && (
              <p className="text-gray-400 italic">Dein Einkaufszettel ist leer. Füge Artikel hinzu, um loszulegen!</p>
            )}
          </div>
          
          {/* Most used items */}
          <div className="mb-6">
            <h2 className="text-lg md:text-xl mb-2">Meist verwendete Artikel</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
              {mostUsedItems.map((item) => (
                <ItemTile 
                  key={item.id} 
                  icon={item.icon} 
                  name={item.name} 
                  color={item.isShoppingListItem ? SHOPPING_LIST_COLOR : DEFAULT_ITEM_COLOR}
                  onToggleShoppingList={() => toggleShoppingListStatus(item.id)} 
                  onAddDetail={(detail) => addDetailToItem(item.id, detail)} 
                  detail={item.detail}
                  onOpenSettings={() => openItemSettings(item)}
                />
              ))}
            </div>
          </div>

          {/* Categories */}
          {categories.map((category) => (
            <div key={category.id} className="mb-6">
              <div 
                className="flex items-center cursor-pointer mb-2 p-2 bg-gray-700 rounded-lg"
                onClick={() => toggleCategory(category.id)}
              >
                {expandedCategories[category.id] ? (
                  <ChevronDown size={20} className="mr-2" />
                ) : (
                  <ChevronRight size={20} className="mr-2" />
                )}
                <h2 className="text-lg md:text-xl">{category.name}</h2>
              </div>
              {expandedCategories[category.id] && (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-2">
                  {items
                    .filter(item => item.category?.id === category.id)
                    .map((item) => (
                      <ItemTile 
                        key={item.id} 
                        icon={item.icon} 
                        name={item.name} 
                        color={item.isShoppingListItem ? SHOPPING_LIST_COLOR : DEFAULT_ITEM_COLOR}
                        onToggleShoppingList={() => toggleShoppingListStatus(item.id)}
                        onAddDetail={(detail) => addDetailToItem(item.id, detail)}
                        detail={item.detail}
                        onOpenSettings={() => openItemSettings(item)}
                      />
                    ))
                  }
                </div>
              )}
            </div>
          ))}
        </>
      )}

      {/* Item Settings Modal */}
      {selectedItem && (
        <ItemSettingsModal
          item={selectedItem}
          onClose={closeItemSettings}
          onUpdateItem={updateItemDetails}
        />
      )}
    </div>
  );
};

export default ShoppingList;
