import React, { useState, useCallback, memo } from 'react';
import { MoreVertical } from 'lucide-react';

const ItemTile = memo(({ icon, name, color, detail, onAddDetail, onToggleShoppingList, onOpenSettings }) => {
  const [isEditingDetail, setIsEditingDetail] = useState(false);
  const [newDetail, setNewDetail] = useState(detail || '');

  const handleDetailChange = useCallback(() => {
    if (newDetail.trim() !== detail) {
      console.log('Saving new detail:', newDetail.trim());
      onAddDetail(newDetail.trim());
    }
    setIsEditingDetail(false);
  }, [newDetail, detail, onAddDetail]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDetailChange();
    }
  };

  return (
    <div
      className={`relative max-w-md aspect-square ${color} rounded-lg flex flex-col items-center justify-between p-2 cursor-pointer`}
      onClick={() => {
        if (!isEditingDetail) {
          onToggleShoppingList();
        }
      }}
    >
      {/* Settings button - always visible */}
      <button 
        className="absolute top-1 right-1 bg-black bg-opacity-30 text-white p-2 rounded-lg"
        onClick={(e) => {
          e.stopPropagation();
          onOpenSettings();
        }}
      >
        <MoreVertical size={20} />
      </button>
      
      {/* Icon */}
      <div className="w-1/2 aspect-square mb-2 mt-4">
        <img src={icon} alt={name} className="w-full h-full object-contain" />
      </div>

      {/* Item name */}
      <span className="text-sm text-center text-white font-semibold leading-tight">{name}</span>
      
      {/* Detail section */}
      {!isEditingDetail ? (
        <span 
          className="text-xs text-center text-white mt-1 cursor-text overflow-hidden text-ellipsis w-full"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditingDetail(true);
          }}
        >
          {detail || 'Detail hinzufügen'}
        </span>
      ) : (
        <input
          type="text"
          value={newDetail}
          onChange={(e) => setNewDetail(e.target.value)}
          onBlur={handleDetailChange}
          onKeyDown={handleKeyDown}
          className="w-full text-xs text-black p-1 rounded mt-1"
          onClick={(e) => e.stopPropagation()}
          autoFocus
        />
      )}
    </div>
  );
});

export default ItemTile;