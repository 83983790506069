import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const API_URL = 'https://api.uf-am-boda.ch:5000/api';

const ItemSettingsModal = ({ item, onClose, onUpdateItem }) => {
  const [newIcon, setNewIcon] = useState(item.icon);
  const [newCategory, setNewCategory] = useState(item.category ? item.category.id : '');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch categories from the API
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_URL}/categories`);
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      setCategories(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories. Please try again.');
      setIsLoading(false);
    }
  };

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewIcon(reader.result);  // This should be a Base64 string
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCategoryChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleSave = () => {
    const updatedItem = {
      name: item.name,
      icon: newIcon,
      category: newCategory || 1,  // Setzen Sie auf 1 (Sonstiges), wenn keine Kategorie ausgewählt ist
      isShoppingListItem: item.isShoppingListItem,
      useCount: item.useCount,
      detail: item.detail
    };
    onUpdateItem(item.id, updatedItem);
    onClose();
  };

  if (isLoading) {
    return <div>Lädt...</div>;
  }

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg max-w-sm w-full shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">Bearbeite Artikel: {item.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="mb-6">
          <div className="flex items-center gap-4 mb-4">
            <div className="icon-background-red">
              <img src={newIcon} alt={item.name} className="w-16 h-16 object-cover rounded" />
            </div>
            <div>
              <h3 className="font-semibold text-gray-800">{item.name}</h3>
              <p className="text-sm text-gray-600">{item.detail || 'Keine Details'}</p>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Icon</label>
            <input
              type="file"
              onChange={handleIconChange}
              className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kategorie</label>
            <select
              value={newCategory}
              onChange={handleCategoryChange}
              className="w-full p-2 border border-gray-300 rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Änderungen speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemSettingsModal;
